import { getCookie } from '@/utills'
import { HUBSPOT_PORTAL_ID } from '@/utills/constants'

function buildLegalConsentOptions(
    legalConsent: 'none' | 'lead' | 'gatedContent' | 'marketing',
    headers: string[],
) {
    if (legalConsent === 'lead') {
        return {
            legitimateInterest: {
                value: true,
                subscriptionTypeId: 999,
                legalBasis: 'LEAD',
                text: headers.join(' '),
            },
        }
    }

    if (legalConsent === 'gatedContent') {
        return {
            consent: {
                consentToProcess: true,
                text: headers.join(' '),
                communications: [
                    {
                        value: false,
                        subscriptionTypeId: 999,
                        text: headers.join(' '),
                    },
                ],
            },
        }
    }

    if (legalConsent === 'marketing') {
        return {
            consent: {
                consentToProcess: true,
                text: headers.join(' '),
                communications: [
                    {
                        value: true,
                        subscriptionTypeId: 999,
                        text: headers.join(' '),
                    },
                ],
            },
        }
    }

    return {
        consent: {
            consentToProcess: false,
            text: headers.join(' '),
            communications: [
                {
                    value: false,
                    subscriptionTypeId: 999,
                    text: headers.join(' '),
                },
            ],
        },
    }
}

export const useNewsletter = (hubspotFormId: string, message?: string[]) => {
    const headers = message ?? [
        'Subscribe to our monthly newsletter',
        '– and get a free copy of our Complete Guide to Offsetting',
    ]

    const isValidEmail = (e: any) => {
        const regexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
        return !!e && regexp.test(e)
    }

    async function toHubspot(
        props: (
            | {}
            | {
                  name: string
              }
            | {
                  firstname: string
                  lastname: string
              }
        ) & {
            email: string
            job?: string
            company?: string
            legalConsent: 'lead' | 'marketing' | 'gatedContent' | 'none'
            message?: string
            money2020Answer?: string
            phone?: string
            intent?: string
        },
    ) {
        const { email, job, company, message, legalConsent, money2020Answer, phone, intent } = props

        let firstname = ''
        let lastname = ''
        if ('name' in props) {
            const words = props.name.split(' ')
            firstname = words[0]
            lastname = words.slice(1).join(' ')
        } else if ('firstname' in props && 'lastname' in props) {
            firstname = props.firstname
            lastname = props.lastname
        }

        const legalConsentOptions = buildLegalConsentOptions(legalConsent, headers)

        const hutk = getCookie('hubspotutk', document.cookie)

        const payload = {
            fields: [
                {
                    objectTypeId: '0-1',
                    name: 'email',
                    value: email,
                },
                {
                    objectTypeId: '0-1',
                    name: 'firstname',
                    value: firstname,
                },
                {
                    objectTypeId: '0-1',
                    name: 'lastname',
                    value: lastname,
                },
                {
                    objectTypeId: '0-1',
                    name: 'jobtitle',
                    value: job ?? '',
                },
                ...(company
                    ? [
                          {
                              objectTypeId: '0-1',
                              name: 'company',
                              value: company,
                          },
                      ]
                    : []),
                ...(message
                    ? [
                          {
                              objectTypeId: '0-1',
                              name: 'message',
                              value: message,
                          },
                      ]
                    : []),
                ...(money2020Answer
                    ? [
                          {
                              objectTypeId: '0-1',
                              name: 'money2020_answer',
                              value: money2020Answer,
                          },
                      ]
                    : []),
                ...(phone
                    ? [
                          {
                              objectTypeId: '0-1',
                              name: 'phone',
                              value: phone,
                          },
                      ]
                    : []),
                ...(intent
                    ? [
                          {
                              objectTypeId: '0-1',
                              name: 'intent',
                              value: intent,
                          },
                      ]
                    : []),
            ],
            legalConsentOptions: {
                ...legalConsentOptions,
            },
            ...(hutk ? { hutk } : {}),
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        }

        return fetch(
            `https://api.hsforms.com/submissions/v3/integration/submit/${HUBSPOT_PORTAL_ID}/${hubspotFormId}`,
            requestOptions,
        ).then((response) => {
            /* @ts-ignore: ... */
            gtag('event', 'form_submission', {
                hubspotFormId,
            })
            return response.json()
        })
    }

    return {
        isValidEmail,
        toHubspot,
    }
}
