import type { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import Image, { StaticImageData } from 'next/image'

const LogosGrid = ({
    logos,
    sx,
}: {
    logos: {
        name: string
        img: StaticImageData
        width: number
        height: number
    }[]
    sx?: SxProps
}) => (
    <Box
        sx={{
            width: `100%`,
            display: 'flex',
            justifyContent: {
                xs: 'start',
                sm: 'start',
                md: 'space-between',
                lg: 'space-between',
                xl: 'space-between',
            },
            alignItems: 'center',
            flexWrap: 'wrap',
            columnGap: {
                xs: 7,
                sm: 7,
                md: 0,
                lg: 0,
                xl: 0,
            },
            rowGap: 5,
            ...sx,
        }}
    >
        {logos.map(({ img, name, width, height }, index) => (
            <Box
                key={index}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: {
                        xs: 'auto',
                        sm: 'auto',
                        md: `auto`,
                        lg: `${100 / logos.length}%`,
                        xl: `${100 / logos.length}%`,
                    },
                    justifyContent: 'center',
                }}
            >
                <Image src={img} alt={`${name} logo`} width={width} height={height} />
            </Box>
        ))}
    </Box>
)

export default LogosGrid
