'use client'

import { LuneTheme } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import Text from '@mui/material/Typography'
import { SxProps } from '@mui/system'
import Image from 'next/image'
import Link from 'next/link'
import { FC, ReactNode } from 'react'

interface BlogCardProps {
    imageSrc: string
    imageFill?: boolean
    title: ReactNode
    formattedDate?: string
    href: string
    label?: string
    description?: string
    sx?: SxProps
    onClick?: () => void
}

const BlogCardOld: FC<BlogCardProps> = ({
    imageSrc,
    imageFill,
    href,
    formattedDate,
    title,
    label,
    description,
    sx,
    onClick,
}) => {
    const { palette } = LuneTheme
    return (
        <Box
            sx={{
                display: 'flex',
                '&:hover, &:focus': {
                    '.blog-post-title': {
                        textDecoration: 'underline',
                    },
                },
                ...sx,
            }}
        >
            <Link
                href={href}
                style={{
                    width: '100%',
                    color: palette.Grey900,
                    textDecoration: 'none',
                }}
                onClick={onClick}
            >
                <Box
                    sx={{
                        gap: '24px',
                        display: 'flex',
                        alignItems: { xs: 'center', sm: 'center', md: 'flex-start' },
                        justifyContent: 'space-between',
                        flexDirection: { xs: 'row', sm: 'row', md: 'column' },
                    }}
                >
                    <Box
                        sx={{
                            paddingBottom: { xs: '0%', sm: '0%', md: '53.12%' },
                            height: { xs: '160px', sm: '200px', md: '0' },
                            width: { xs: '160px', sm: '200px', md: '100%' },
                            minWidth: { xs: '160px', sm: '200px', md: '100%' },
                            maxWidth: { xs: '160px', sm: '200px', md: '100%' },
                            position: 'relative',
                            overflow: 'hidden',
                            borderRadius: '20px',
                            mb: { xs: 0, sm: 0, md: 3 },
                            flex: '1 1 auto',
                            img: {
                                position: 'absolute',
                                top: 0,
                                width: 'auto',
                                height: '100%',
                                zIndex: -1,
                                right: '-50%',
                                left: '-50%',
                                margin: 'auto',
                            },
                        }}
                    >
                        <Image
                            src={imageSrc}
                            alt={`blog-${title}-image`}
                            {...(imageFill
                                ? { fill: true, style: { objectFit: 'cover' } }
                                : { width: 500, height: 500 })}
                        />
                    </Box>
                    <Box
                        sx={{
                            flex: '1 1 auto',
                            display: 'flex',
                            gap: { xs: 2, sm: 2, md: 3 },
                            flexDirection: 'column',
                        }}
                    >
                        {label && (
                            <Text variant={'button'} sx={{ textTransform: 'uppercase' }}>
                                {label}
                            </Text>
                        )}
                        <Text
                            className={'blog-post-title'}
                            sx={{
                                fontSize: { xs: '14px', sm: '18px' },
                                fontWeight: { xs: 500, sm: 700 },
                                lineHeight: '120%',
                            }}
                            variant={'body2'}
                        >
                            {title}
                        </Text>
                        {description && <Text variant={'body2'}>{description}</Text>}
                        {formattedDate && <Text variant={'caption'}>{formattedDate}</Text>}
                    </Box>
                </Box>
            </Link>
        </Box>
    )
}

export default BlogCardOld
