import Box from '@mui/material/Box'
import Text from '@mui/material/Typography'
import EELeader from '@public/images/logos/EELeader.png'
import Finextra from '@public/images/logos/finextra.png'
import GreenBiz from '@public/images/logos/greenbiz.png'
import HavardBusinessReview from '@public/images/logos/harvard-business-review.png'
import Maddyness from '@public/images/logos/maddyness.png'
import Sifted from '@public/images/logos/sifted.png'
import TechCrunch from '@public/images/logos/techcrunch.png'
import TheEnergyst from '@public/images/logos/the-energyst.png'
import ThePaypers from '@public/images/logos/the-paypers.png'

import LogosGrid from '@/components/LogosGrid'
import useDevice from '@/hooks/useDevice'

const logos1 = [
    {
        name: 'Sifted',
        img: Sifted,
        width: 89.03,
        height: 24,
    },
    {
        name: 'GreenBiz',
        img: GreenBiz,
        width: 99,
        height: 20,
    },
    {
        name: 'TechCrunch',
        img: TechCrunch,
        width: 139.87,
        height: 20,
    },
    {
        name: 'Finextra',
        img: Finextra,
        width: 87.5,
        height: 18,
    },
    {
        name: 'ThePaypers',
        img: ThePaypers,
        width: 107.77,
        height: 24,
    },
]
const logos2 = [
    {
        name: 'HavardBusinessReview',
        img: HavardBusinessReview,
        width: 53.91,
        height: 32,
    },
    {
        name: 'Maddyness',
        img: Maddyness,
        width: 121.21,
        height: 24,
    },
    {
        name: 'The Energyst',
        img: TheEnergyst,
        width: 146,
        height: 24,
    },
    {
        name: 'E+ELeader',
        img: EELeader,
        width: 139,
        height: 22,
    },
]

const AsSeenInLogos = () => {
    const { smScreen, mdScreen } = useDevice()

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: smScreen ? 'flex-start' : 'center',
                gap: smScreen ? 7 : 6,
                flexDirection: 'column',
            }}
        >
            <Text
                variant={mdScreen ? 'h5' : 'h4'}
                sx={{
                    textAlign: smScreen ? 'left' : 'center',
                }}
            >
                As seen in
            </Text>
            <Box sx={{ width: '100%' }}>
                {smScreen ? (
                    <LogosGrid logos={[...logos1, ...logos2]} />
                ) : (
                    <>
                        <LogosGrid sx={{ height: '120px', lineHeight: '120px' }} logos={logos1} />
                        <LogosGrid
                            sx={{ height: '120px', lineHeight: '120px', marginTop: '-48px' }}
                            logos={logos2}
                        />
                    </>
                )}
            </Box>
        </Box>
    )
}

export default AsSeenInLogos
