export const HUBSPOT_PORTAL_ID = 25160067

// COPIED FROM OLD WEBSITE
export const HUBSPOT_FORM_IDS = {
    home: 'b81434f9-0886-4989-b93e-4ead00fa371e',
    // about: 'b81434f9-0886-4989-b93e-4ead00fa371e',
    // product: 'b81434f9-0886-4989-b93e-4ead00fa371e',
    // customers: 'b81434f9-0886-4989-b93e-4ead00fa371e',
    // retail: 'dc7a2bf0-f84e-4037-91a7-2f1e74979968',
    // payments: 'c266f62f-d8c3-4243-bb83-94fe7994051f',
    // other: 'b81434f9-0886-4989-b93e-4ead00fa371e',
    blog: '61b89c03-d03d-4314-a556-e6226d563c6d',
    // logistics: '8f916e5c-b418-4e12-a84b-a3f17873bafd',
    projects: '99740f76-85df-450b-8b73-2eced32aa7eb',
    // procurement: '113ae8b2-6bab-4d7c-ac70-f3cd8de3127f',
    // buycarboncredits: 'b652ef65-de68-414d-a7db-4db379a59107',
    greenForm: '99740f76-85df-450b-8b73-2eced32aa7eb',
    purpleForm: '3975a487-5193-411c-97f1-9a379cb2865b',
    businessSpend: '4337e208-76f2-47bb-8f9c-05aa0e0fdc28',
    money2020: '04423f58-f264-4c4f-9f32-915d4d68a485',
}
