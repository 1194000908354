'use client'

import { ListItemLayout, LuneTheme } from '@lune-fe/lune-ui-lib'
import type { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import Text from '@mui/material/Typography'
import Image from 'next/image'
import { FC, ReactNode } from 'react'

import TwoColumnsWithBackgroundImage from '@/components/TwoColumnsWithBackgroundImage'
import useDevice from '@/hooks/useDevice'

const CustomerQuote: FC<{
    backgroundImageSrc: string
    mobileBackgroundImageSrc: string
    logo?: ReactNode
    text?: ReactNode
    title?: ReactNode
    light?: boolean
    avatar: {
        imageSrc: string
        title: string
        subtitle: string
    }
    sx?: SxProps
    reversedOnMobile?: boolean
}> = ({
    backgroundImageSrc,
    mobileBackgroundImageSrc,
    logo,
    text,
    avatar,
    light,
    title,
    sx,
    reversedOnMobile,
}) => {
    const { mdScreen, smScreen } = useDevice()
    const { palette } = LuneTheme

    return (
        <TwoColumnsWithBackgroundImage
            backgroundImage={smScreen ? mobileBackgroundImageSrc : backgroundImageSrc}
            mobileBackgroundImage={mobileBackgroundImageSrc}
            leftSide={
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        gap: mdScreen ? 3 : 4,
                        flexDirection: 'column',
                    }}
                >
                    <Text
                        variant={'button'}
                        sx={{ color: light ? `${palette.White}` : `${palette.Grey900}` }}
                    >
                        CUSTOMER QUOTE
                    </Text>
                    {logo}
                    {title && (
                        <Text
                            sx={{ color: light ? `${palette.White}` : `${palette.Grey900}` }}
                            variant={mdScreen ? 'h5' : 'h4'}
                        >
                            {title}
                        </Text>
                    )}
                    {text && (
                        <Text
                            sx={{ color: light ? `${palette.White}` : `${palette.Grey900}` }}
                            variant={mdScreen ? 'body2' : 'body1'}
                        >
                            {text}
                        </Text>
                    )}
                    <ListItemLayout
                        sx={{
                            '.MuiListItemAvatar-root': {
                                maxWidth: '72px',
                                maxHeight: '72px',
                            },
                        }}
                        title={
                            <Text
                                sx={{ color: light ? `${palette.White}` : `${palette.Grey900}` }}
                                variant={'h6'}
                            >
                                {avatar.title}
                            </Text>
                        }
                        subTitle={
                            <Text
                                sx={{ color: light ? `${palette.White}` : `${palette.Grey900}` }}
                                variant={'body3'}
                            >
                                {avatar.subtitle}
                            </Text>
                        }
                        image={
                            <Image
                                src={avatar.imageSrc}
                                width={72}
                                height={72}
                                alt={`customer quote ${text}`}
                            />
                        }
                    />
                </Box>
            }
            reversedOnMobile={reversedOnMobile ?? false}
            sx={sx}
        />
    )
}

export default CustomerQuote
