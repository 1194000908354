import { Button, Chip } from '@lune-fe/lune-ui-lib'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined'
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined'
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined'
import Box from '@mui/material/Box'
import Text from '@mui/material/Typography'
import Image from 'next/image'
import { FC } from 'react'

import { RenderOn } from '@/utills/DeviceUtils'

const ChipColors = {
    REMOVED: '#73F0D6',
    AVOIDED: '#A1ECFF',
    EMITTED: '#F7ED7E',
}

const ChipIcons = {
    REMOVED: <ArrowDownwardOutlinedIcon />,
    AVOIDED: <CloudOffOutlinedIcon />,
    EMITTED: <ArrowUpwardOutlinedIcon />,
}

const chips = [
    {
        label: '25 tCO₂e removed',
        icon: ChipIcons.REMOVED,
        color: ChipColors.REMOVED,
        rightPosition: '46%',
    },
    {
        label: '0.5 tCO₂e avoided',
        icon: ChipIcons.AVOIDED,
        color: ChipColors.AVOIDED,
        rightPosition: '56%',
    },
    {
        label: '8 tCO₂e emitted',
        icon: ChipIcons.EMITTED,
        color: ChipColors.EMITTED,
        rightPosition: '20%',
    },
    {
        label: '400 tCO₂e emitted',
        icon: ChipIcons.EMITTED,
        color: ChipColors.EMITTED,
        rightPosition: '70%',
    },
    {
        label: '220 tCO₂e removed',
        icon: ChipIcons.REMOVED,
        color: ChipColors.REMOVED,
        rightPosition: '50%',
    },
    {
        label: '40 tCO₂e avoided',
        icon: ChipIcons.AVOIDED,
        color: ChipColors.AVOIDED,
        rightPosition: '0%',
    },
    {
        label: '0.2 tCO₂e avoided',
        icon: ChipIcons.AVOIDED,
        color: ChipColors.AVOIDED,
        rightPosition: '16',
    },
    {
        label: '80 tCO₂e emitted',
        icon: ChipIcons.EMITTED,
        color: ChipColors.EMITTED,
        rightPosition: '6%',
    },
    {
        label: '120 tCO₂e removed',
        icon: ChipIcons.REMOVED,
        color: ChipColors.REMOVED,
        rightPosition: '60%',
    },
]

const HomeHero: FC = () => {
    return (
        <Box
            sx={{
                height: {
                    xs: '720px',
                    md: '792px',
                },
                mt: '-72px',
                position: 'relative',
                img: {
                    height: '100%',
                    width: 'auto',
                    position: 'absolute',
                    left: '-100%',
                    right: '-100%',
                    margin: 'auto',
                    top: 0,
                    zIndex: '-1',
                },
            }}
        >
            <RenderOn breakPoints={['xs', 'sm']}>
                <Image
                    width={1800}
                    height={1440}
                    src={'/images/heros/homepage-mobile.png'}
                    alt={'page-hero-mobile'}
                />
            </RenderOn>
            <RenderOn breakPoints={['md', 'lg', 'xl']}>
                <Image
                    width={4000}
                    height={1584}
                    src={'/images/heros/homepage-desktop.png'}
                    alt={'page-hero-desktop'}
                />
            </RenderOn>
            <Box
                sx={{
                    height: '100%',
                    display: 'flex',
                    pt: {
                        xs: 3,
                        md: 0,
                    },
                }}
            >
                <Box
                    sx={{
                        width: {
                            xs: '100%',
                            md: '50%',
                        },
                        height: '100%',
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: {
                            xs: 'flex-end',
                            md: 'center',
                        },
                        flexDirection: 'column',
                        gap: {
                            xs: 3,
                            md: 5,
                        },
                    }}
                >
                    <RenderOn breakPoints={['xs', 'sm']}>
                        <Text variant={'h5'}>Build a climate positive legacy</Text>
                    </RenderOn>
                    <RenderOn breakPoints={['xs', 'sm']}>
                        <Text variant={'body2'}>
                            Future-proof business growth by funding vetted climate projects and
                            calculating scope 3 emissions with Lune.
                        </Text>
                    </RenderOn>

                    <RenderOn breakPoints={['md', 'lg', 'xl']}>
                        <Text variant={'h3'}>
                            Build a climate <> </>
                            <RenderOn breakPoints={['lg', 'xl']}>
                                <br />
                            </RenderOn>
                            positive legacy
                        </Text>
                    </RenderOn>
                    <RenderOn breakPoints={['md', 'lg', 'xl']}>
                        <Text variant={'body1'}>
                            Grow revenue by embedding scope 3 emission calculations and high quality
                            climate projects into your offering. Fast.
                        </Text>
                    </RenderOn>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            flexDirection: {
                                xs: 'column',
                                sm: 'column',
                                md: 'row',
                                lg: 'row',
                                xl: 'row',
                            },
                            gap: 2,
                            width: {
                                xs: '100%',
                                sm: '100%',
                                md: 'unset',
                            },
                            '.MuiButtonBase-root': {
                                width: {
                                    xs: '100%',
                                    sm: '100%',
                                    md: 'unset',
                                },
                                mb: {
                                    xs: 3,
                                    sm: 3,
                                    md: 0,
                                },
                            },
                        }}
                    >
                        <Button
                            variant={'contained'}
                            leftIcon={<ArrowCircleRightIcon />}
                            href="/request-a-demo"
                            sx={{
                                mb: {
                                    xs: `0 !important`,
                                    sm: `0 !important`,
                                },
                            }}
                        >
                            Request a demo
                        </Button>
                        <Button variant={'text'} href="/contact-us">
                            Contact us
                        </Button>
                    </Box>
                </Box>
                <RenderOn
                    breakPoints={['md', 'lg', 'xl']}
                    sx={{
                        position: 'relative',
                        width: {
                            xs: '100%',
                            md: '50%',
                        },
                    }}
                >
                    {chips.map((chip, index) => {
                        const animationName = `animation-${index + 1}`
                        return (
                            <Chip
                                key={index}
                                icon={chip.icon}
                                label={chip.label}
                                sx={{
                                    background: chip.color,
                                    position: 'absolute',
                                    right: chip.rightPosition,
                                    bottom: '-10%',
                                    height: '32px',
                                    opacity: 0,
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    svg: {
                                        height: '16px',
                                        width: '16px',
                                    },
                                    [`@keyframes ${animationName}`]: {
                                        '0%': {
                                            bottom: '0px',
                                            opacity: 0,
                                        },
                                        '3%': {
                                            opacity: 1,
                                        },
                                        '30%': {
                                            opacity: 1,
                                        },
                                        '35%': {
                                            opacity: 0,
                                        },
                                        '100%': {
                                            bottom: '200%',
                                            opacity: 0,
                                        },
                                    },
                                    animation: `${animationName} 15s cubic-bezier(0, 0.21, 0.99, 0.79) infinite`,
                                    animationDelay: `${index * 2}s`,
                                }}
                            />
                        )
                    })}
                </RenderOn>
            </Box>
        </Box>
    )
}

export default HomeHero
