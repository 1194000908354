'use client'

import Box from '@mui/material/Box'

import HomeHero from '@/components/Hero/HomeHero'
import Subscribe from '@/components/Subscribe'
import useDevice from '@/hooks/useDevice'
import AsSeenInLogos from '@/sections/AsSeenInLogos'
import CTATilesSection from '@/sections/CTATilesSection'
import FortoCustomerStoryWithPercentage from '@/sections/FortoCustomerStoryWithPercentage'
import HomePageBlogPosts from '@/sections/HomePageBlogPosts'
import HomepageLogos from '@/sections/HomepageLogos'
import IdeoCustomerQuote from '@/sections/IdeoCustomerQuote'
import PayhawkCaseStudyBox from '@/sections/PayhawkCaseStudyBox'
import VisaCustomerQuote from '@/sections/VisaCustomerQuote'
import { HUBSPOT_FORM_IDS } from '@/utills/constants'

export default function Home() {
    const { smScreen } = useDevice()

    return (
        <>
            <Box>
                <HomeHero />
                <HomepageLogos />
            </Box>
            <CTATilesSection />
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: smScreen ? 0 : 20,
                }}
            >
                <VisaCustomerQuote />
                <PayhawkCaseStudyBox />
            </Box>
            <AsSeenInLogos />
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: smScreen ? 0 : 20,
                }}
            >
                <FortoCustomerStoryWithPercentage />
                <IdeoCustomerQuote />
            </Box>
            <HomePageBlogPosts />
            <Subscribe hubspotFormId={HUBSPOT_FORM_IDS.blog} />
        </>
    )
}
