import Visa from '@public/images/logos/visa-white.png'
import Image from 'next/image'

import CustomerQuote from '@/components/CustomerQuote'

const VisaCustomerQuote = () => (
    <CustomerQuote
        light={true}
        backgroundImageSrc={'/images/customer-quotes/visa-quote-desktop.png'}
        mobileBackgroundImageSrc={'/images/customer-quotes/visa-quote-mobile.png'}
        logo={<Image src={Visa} alt={`Visa Logo`} height={24} width={74.16} />}
        text={
            <>
                Sustainability is a growing focus in the payment ecosystem, and we are building our
                capabilities at Visa to meet that demand.
                <br />
                <br />
                Through our partnership with Lune, we aim to make it easier for our clients to take
                meaningful climate action, by embedding emission calculations and carbon offsetting
                into their product offering.
            </>
        }
        avatar={{
            title: 'Jeni Mundy',
            subtitle: 'Global SVP Merchant Sales and Acquiring at Visa',
            imageSrc: '/images/avatars/visa-customer-quote-avatar.png',
        }}
    />
)

export default VisaCustomerQuote
