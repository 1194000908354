'use client'

import { Button, Input } from '@lune-fe/lune-ui-lib'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined'
import Box from '@mui/material/Box'
import { FC, useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'

import BlueBox from '@/components/BlueBox'
import useDevice from '@/hooks/useDevice'
import { useNewsletter } from '@/hooks/useNewsLetter'

const InputWithButton: FC<{ onSuccess: (value: boolean) => void; hubspotFormId: string }> = ({
    onSuccess,
    hubspotFormId,
}) => {
    const { mdScreen, xsScreen } = useDevice()
    const { isValidEmail, toHubspot } = useNewsletter(hubspotFormId)

    const [email, setEmail] = useState<string>('')
    const [emailError, setEmailError] = useState<boolean>(false)
    const [debouncedEmail] = useDebounce(email, 1000)

    useEffect(() => {
        if (isValidEmail(email) || email === '') {
            setEmailError(false)
        } else {
            setEmailError(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedEmail])

    const onSubmit = async (email: string) => {
        const validEmail = isValidEmail(email)

        if (!validEmail) {
            setEmailError(true)
            return
        }

        const data = await toHubspot({
            email,
            legalConsent: 'gatedContent',
        })

        if (data.status === 'error') {
            onSuccess(false)
        } else {
            onSuccess(true)
            setEmail('')
        }
    }

    return (
        <Box
            sx={{
                position: 'relative',
                minWidth: xsScreen ? '100%' : mdScreen ? '350px' : '504px',
                height: '56px',
            }}
        >
            <Input
                placeholder={'Email'}
                error={emailError}
                sx={{
                    width: '100%',
                    position: 'absolute',
                    left: '0',
                    input: {
                        pr: 5,
                    },
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <Button
                disabled={!email.length || emailError}
                sx={{
                    position: 'absolute',
                    right: '4px',
                    top: '4px',
                }}
                variant={'text'}
                iconButton
                leftIcon={<ArrowForwardOutlinedIcon />}
                onClick={() => onSubmit(email)}
            />
        </Box>
    )
}

const Subscribe: FC<{ hubspotFormId: string }> = ({ hubspotFormId }) => {
    const [success, setSuccess] = useState<boolean>(false)

    return (
        <BlueBox
            text={
                success
                    ? 'Thank you for subscribing to our newsletter, The Carbon Cache. Check your inbox for a surprise from us!'
                    : 'Subscribe for the latest insights into driving climate positivity'
            }
            rightSide={
                success ? (
                    <></>
                ) : (
                    <InputWithButton hubspotFormId={hubspotFormId} onSuccess={setSuccess} />
                )
            }
        />
    )
}

export default Subscribe
