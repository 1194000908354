import Image from 'next/image'

import CustomerQuote from '@/components/CustomerQuote'

const IdeoCustomerQuote = () => {
    return (
        <CustomerQuote
            backgroundImageSrc={'/images/customer-quotes/ideo-quote-desktop.png'}
            mobileBackgroundImageSrc={'/images/customer-quotes/ideo-quote-mobile.png'}
            logo={
                <Image
                    src={'/images/logos/ideo.png'}
                    height={19}
                    width={83.12}
                    alt={'Ideo customer quote'}
                />
            }
            text={
                <>
                    Lune is our preferred partner due to the emphasis on quality, real impact, and
                    transparency – something that is generally lacking in the carbon markets.
                    <br />
                    <br />
                    We are committed to credible offsetting and, going forward, aim to increase our
                    spend on permanent carbon removal projects in line with the Oxford Offsetting
                    Principles.
                </>
            }
            avatar={{
                title: 'Derek Robson',
                subtitle: 'CEO at IDEO',
                imageSrc: '/images/avatars/IDEO-customer-quote-avatar.png',
            }}
        />
    )
}

export default IdeoCustomerQuote
