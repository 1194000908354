'use client'

import { LuneTheme } from '@lune-fe/lune-ui-lib'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Box from '@mui/material/Box'
import Text from '@mui/material/Typography'
import Image, { StaticImageData } from 'next/image'
import Link from 'next/link'
import { FC } from 'react'

import LuneButton from '@/components/LuneButton'
import { RenderOn } from '@/utills/DeviceUtils'

export interface IllustratedTile {
    image: string | StaticImageData
    title: string
    href: string
    imageAlt: string
}

const Tile: FC<IllustratedTile> = ({ title, href, image, imageAlt }) => {
    const theme = LuneTheme

    return (
        <>
            <RenderOn breakPoints={['xs', 'sm', 'md']}>
                <Link
                    href={href}
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        textDecoration: 'none',
                        width: '100%',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                width: 87,
                                background: theme.palette.Grey100,
                                borderRadius: `8px`,
                                height: 60,
                                display: 'flex',
                                justifyContent: 'center',
                                position: 'relative',
                                overflow: 'hidden',
                            }}
                        >
                            <Image
                                style={{
                                    bottom: 0,
                                    position: 'absolute',
                                }}
                                width={80}
                                height={53.98}
                                src={image}
                                alt={imageAlt}
                            />
                        </Box>
                        <Text
                            sx={{
                                color: theme.palette.Grey900,
                                ml: 3,
                            }}
                            variant={`button`}
                        >
                            {title}
                        </Text>
                    </Box>

                    <ArrowForwardIosIcon
                        sx={{
                            color: theme.palette.Grey900,
                            fontSize: 16,
                            fontWeight: `bold`,
                            mr: 1,
                        }}
                    />
                </Link>
            </RenderOn>
            <RenderOn breakPoints={['lg', 'xl']}>
                <Box
                    sx={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: {
                            xs: 'column',
                            sm: 'column',
                            md: 'row',
                            lg: 'row',
                            xl: 'row',
                        },
                        height: 192,
                    }}
                >
                    <Link href={href}>
                        <Box
                            sx={{
                                width: 178,
                                display: 'flex',
                                justifyContent: 'center',
                                '&:hover, &:focus, &:focus-within': {
                                    '.MuiSvgIcon-root': {
                                        transform: 'translateX(4px)',
                                    },
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    width: 178,
                                    background: theme.palette.Grey100,
                                    borderRadius: `24px`,
                                    height: 120,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    position: 'relative',
                                    overflow: 'hidden',
                                }}
                            >
                                <Image
                                    style={{
                                        bottom: 0,
                                        position: 'absolute',
                                    }}
                                    width={164}
                                    height={110.65}
                                    src={image}
                                    alt={imageAlt}
                                />
                            </Box>
                            <LuneButton
                                label={title}
                                sx={{
                                    position: 'absolute',
                                    bottom: 0,
                                }}
                            />
                        </Box>
                    </Link>
                </Box>
            </RenderOn>
        </>
    )
}

const IllustratedMainLinks: FC<{ tiles: IllustratedTile[] }> = ({ tiles }) => (
    <Box
        sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            my: 1,
            gap: {
                xs: 2,
                sm: 2,
                md: 2,
                lg: 0,
                xl: 0,
            },
            flexDirection: {
                xs: 'column',
                sm: 'column',
                md: 'column',
                lg: 'row',
                xl: 'row',
            },
        }}
    >
        {tiles.map((tile, index) => (
            <Tile
                key={index}
                image={tile.image}
                title={tile.title}
                href={tile.href}
                imageAlt={tile.imageAlt}
            />
        ))}
    </Box>
)

export default IllustratedMainLinks
