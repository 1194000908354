'use client'

import Box from '@mui/material/Box'
import Text from '@mui/material/Typography'
import { FC } from 'react'

import CTATile from '@/components/CTATile'
import IllustratedMainLinks from '@/components/IllustratedMainLinks'
import useDevice from '@/hooks/useDevice'

const CTATilesSection: FC = () => {
    const { mdScreen, smScreen } = useDevice()

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: smScreen ? 'flex-start' : 'center',
                gap: smScreen ? 3 : 9,
                flexDirection: 'column',
            }}
        >
            <Text
                variant={mdScreen ? 'h5' : 'h4'}
                sx={{
                    textAlign: smScreen ? 'left' : 'center',
                }}
            >
                Cultivate customer loyalty by streamlining climate compliance and accelerating their
                sustainability goals
            </Text>
            <IllustratedMainLinks
                tiles={[
                    {
                        image: '/illustrations/menu-tile-images/stamp-logistics.png',
                        title: 'Logistics',
                        href: '/logistics',
                        imageAlt: 'Logistics illustration',
                    },
                    {
                        image: '/illustrations/menu-tile-images/stamp-fintech.png',
                        title: 'Spend management',
                        href: '/spend-management',
                        imageAlt: 'Spend management illustration',
                    },
                    {
                        image: '/illustrations/menu-tile-images/stamp-procurement.png',
                        title: 'Procurement',
                        href: '/procurement',
                        imageAlt: 'Procurement illustration',
                    },
                ]}
            />
            <Box
                sx={{
                    display: 'flex',
                    gap: smScreen ? 3 : mdScreen ? 2 : 3,
                    flexDirection: smScreen ? 'column' : 'row',
                }}
            >
                <CTATile
                    backgroundImageUrl={'/images/CTA-tiles/embed-emission-calcs.png'}
                    title={'Embed emission calculations '}
                    description={
                        'Attract new customers by streamlining emission reporting for climate compliance.'
                    }
                    button={{
                        label: 'Calculate emissions',
                        href: '/embed-emission-calculations',
                    }}
                />
                <CTATile
                    backgroundImageUrl={'/images/CTA-tiles/fund-climate-projects.png'}
                    title={'Fund climate projects'}
                    description={
                        'Grow customer value by scaling climate impact using high quality projects.'
                    }
                    button={{
                        label: 'Purchase carbon credits',
                        href: '/fund-climate-projects',
                    }}
                />
            </Box>
        </Box>
    )
}

export default CTATilesSection
