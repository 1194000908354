'use client'

import { Button } from '@lune-fe/lune-ui-lib'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import Box from '@mui/material/Box'
import Text from '@mui/material/Typography'
import Image from 'next/image'
import { useRouter } from 'next/navigation'
import { FC, ReactNode } from 'react'

import useDevice from '@/hooks/useDevice'

const CTATile: FC<{
    backgroundImageUrl: string
    title: ReactNode
    description: ReactNode
    button: {
        label: string
        href: string
    }
}> = ({ backgroundImageUrl, description, title, button }) => {
    const { mdScreen } = useDevice()
    const router = useRouter()

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                flexDirection: 'column',
                height: '640px',
                width: '100%',
                pt: 6,
                pb: 6,
                pr: 3,
                pl: 3,
                position: 'relative',
                overflow: 'hidden',
                borderRadius: '32px',
                img: {
                    position: 'absolute',
                    top: 0,
                    width: 'auto',
                    height: '100%',
                    zIndex: -1,
                    margin: 'auto',
                },
                cursor: 'pointer',
            }}
            onClick={() => {
                router.push(button.href)
            }}
        >
            <Image src={backgroundImageUrl} width={640} height={640} alt={`${title}-image`} />
            <Text
                sx={{
                    mb: 1,
                    color: 'white',
                    textAlign: 'center',
                }}
                variant={mdScreen ? `h6` : `h5`}
            >
                {title}
            </Text>
            <Text
                sx={{
                    color: 'white',
                    textAlign: 'center',
                }}
                variant={mdScreen ? `body2` : `body1`}
            >
                {description}
            </Text>
            <Button
                sx={{ mt: 4 }}
                variant={'contained'}
                light={true}
                leftIcon={<ArrowCircleRightIcon />}
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    router.push(button.href)
                }}
            >
                {button.label}
            </Button>
        </Box>
    )
}

export default CTATile
