import type { SxProps } from '@mui/material'
import Grid from '@mui/material/Grid'
import * as React from 'react'

import useDevice from '@/hooks/useDevice'

export enum ColumnWrapOrder {
    REVERSE = 'reverse',
    NORMAL = 'normal',
}

type MuiValues = number | { xs: number; sm: number; md: number; lg: number; xl: number }

const TwoColumnLayout = ({
    children,
    leftCol,
    rightCol,
    columnWrapOrderOnMobile,

    pb,
    sx,
    alignItems,
}: {
    children: [React.ReactNode, React.ReactNode]
    leftCol?: number
    rightCol?: number
    columnWrapOrderOnMobile: ColumnWrapOrder
    spacing?: MuiValues
    pb?: MuiValues
    sx?: SxProps
    alignItems?: 'flex-start' | 'center' | 'flex-end' | 'baseline'
}) => {
    const { smScreen: isMobile } = useDevice()
    const left = leftCol ?? 6
    const right = rightCol ?? 5
    if (left + right !== 11) {
        throw new Error(`${left} col + ${right} col must equal 12`)
    }

    return (
        <>
            {/* @ts-ignore: ... */}
            <Grid
                container
                spacing={0}
                columns={{ xs: 4, sm: 4, md: 12, lg: 12, xl: 12 }}
                justifyContent="center"
                alignItems={alignItems ?? 'center'}
                height="100%"
                style={{
                    marginTop: '0px',
                    padding: `0px !important`,
                    width: `100%`,
                    ...(sx ?? {}), // adding this to offset `padding: 0px !important`
                }}
                sx={sx || {}}
            >
                <Grid
                    item
                    xs={4}
                    sm={left}
                    md={left}
                    lg={left}
                    xl={left}
                    sx={{
                        p: `0px !important`,
                        m: `0px !important`,
                    }}
                >
                    {columnWrapOrderOnMobile === ColumnWrapOrder.REVERSE && isMobile
                        ? children[1]
                        : children[0]}
                </Grid>
                <Grid
                    sx={{
                        p: `0px !important`,
                        m: `0px !important`,
                    }}
                    item
                    xs={0}
                    sm={1}
                    md={1}
                    lg={1}
                    xl={1}
                />
                <Grid
                    item
                    xs={4}
                    sm={right}
                    md={right}
                    lg={right}
                    xl={right}
                    pb={
                        pb ?? {
                            xs: 10,
                            sm: 0,
                            md: 0,
                            lg: 0,
                            xl: 0,
                        }
                    }
                    sx={{
                        p: `0px !important`,
                        mx: `0px !important`,
                        mb: `0px !important`,
                        mt: isMobile ? 6 : 0,
                    }}
                >
                    {columnWrapOrderOnMobile === ColumnWrapOrder.REVERSE && isMobile
                        ? children[0]
                        : children[1]}
                </Grid>
            </Grid>
        </>
    )
}

export default TwoColumnLayout
