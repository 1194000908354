'use client'

import { Button } from '@lune-fe/lune-ui-lib'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined'
import Box from '@mui/material/Box'
import Text from '@mui/material/Typography'
import { FC } from 'react'

import BlogCardOld from '@/components/BlogCardOld'
import useDevice from '@/hooks/useDevice'

const HomePageBlogPosts: FC = () => {
    const { smScreen } = useDevice()

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: smScreen ? 7 : 9,
                flexDirection: 'column',
            }}
        >
            <Text variant={smScreen ? 'h5' : 'h4'}>Popular insights</Text>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: smScreen ? 5 : 2,
                    flexWrap: 'wrap',
                    alignItems: 'flex-start',
                }}
            >
                <BlogCardOld
                    sx={{ flex: smScreen ? '0 0 100%' : '0 0 calc(33.33% - 16px)' }}
                    imageSrc={
                        'https://images.prismic.io/luneco/aa2513db-5221-46ee-9f65-38d24e65eb53_visa-thumb.png?auto=compress,format'
                    }
                    href={'/blog/lune-visa-partnership-accelerate-climate-action/'}
                    title={'Lune partners with Visa to help accelerate meaningful climate action'}
                    formattedDate={'April 19, 2023'}
                    imageFill
                />
                <BlogCardOld
                    sx={{ flex: smScreen ? '0 0 100%' : '0 0 calc(33.33% - 16px)' }}
                    imageSrc={
                        'https://images.prismic.io/luneco/bb729fb7-9f5f-4a0a-a6a6-1b32a6c3c127_dac-thumbnail.png?auto=compress,format'
                    }
                    href={'/blog/deep-dive-on-direct-air-capture/'}
                    title={
                        'How to suck all that carbon we’ve emitted back out of the atmosphere – a deep dive on Direct Air Capture'
                    }
                    formattedDate={'March 20, 2023'}
                    imageFill
                />
                <BlogCardOld
                    sx={{ flex: smScreen ? '0 0 100%' : '0 0 calc(33.33% - 16px)' }}
                    imageSrc={
                        'https://images.prismic.io/luneco/25d3f44e-0e8f-48e6-9969-b07479cbc0b6_legislation+overview+%281%29-min.png?auto=compress,format'
                    }
                    href={
                        '/blog/climate-disclosure-regulations-are-coming-into-effect-across-the-world-heres-what-you-need-to-know/'
                    }
                    title={
                        'Climate disclosure regulations are coming into effect across the world – here’s what you need to know'
                    }
                    formattedDate={'February 15, 2023'}
                    imageFill
                />
            </Box>
            <Button
                variant={'outlined'}
                href={'/blog'}
                sx={{ width: smScreen ? '100%' : 'auto' }}
                leftIcon={<ArrowForwardOutlinedIcon />}
            >
                Explore our blog
            </Button>
        </Box>
    )
}

export default HomePageBlogPosts
