import { LuneTheme } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import CrownAgentsBank from '@public/images/logos/crown-agents-bank.png'
import Forto from '@public/images/logos/forto.png'
import Ideo from '@public/images/logos/ideo.png'
import Jas from '@public/images/logos/jas.svg'
import Logixboard from '@public/images/logos/logixboard.png'
import Moomin from '@public/images/logos/moomin.png'
import Ovako from '@public/images/logos/ovako.png'
import Payhawk from '@public/images/logos/payhawk.png'
import SalesForce from '@public/images/logos/salesforce.png'
import Visa from '@public/images/logos/visa.png'
import { FC } from 'react'

import LogosGrid from '@/components/LogosGrid'
import useDevice from '@/hooks/useDevice'

const logos = [
    {
        name: 'Visa',
        img: Visa,
        width: 52.45,
        height: 17,
    },
    {
        name: 'SalesForce',
        img: SalesForce,
        width: 59.99,
        height: 42,
    },
    {
        name: 'Ideo',
        img: Ideo,
        width: 74.37,
        height: 17,
    },
    {
        name: 'Forto',
        img: Forto,
        width: 77.68,
        height: 19,
    },
    {
        name: 'Jas',
        img: Jas,
        width: 83.72,
        height: 32,
    },
]
const logos2 = [
    {
        name: 'Ovako',
        img: Ovako,
        width: 71.069,
        height: 17,
    },
    {
        name: 'Payhawk',
        img: Payhawk,
        width: 96.47,
        height: 22,
    },
    {
        name: 'Moomin',
        img: Moomin,
        width: 62.33,
        height: 17,
    },
    {
        name: 'Logixboard',
        img: Logixboard,
        width: 149.3,
        height: 17,
    },
    {
        name: 'Crown Agents Bank',
        img: CrownAgentsBank,
        width: 111.25,
        height: 32,
    },
]

const HomepageLogos: FC<{ removeBorder?: boolean }> = ({ removeBorder }) => {
    const { smScreen } = useDevice()
    const { palette } = LuneTheme
    return (
        <Box
            sx={{
                position: 'relative',
                ...(!removeBorder && {
                    '&:after': {
                        content: '""',
                        position: 'absolute',
                        bottom: 0,
                        left: '-50%',
                        right: '-50%',
                        height: '1px',
                        backgroundColor: `${palette.Grey300}`,
                        mx: {
                            lg: 37,
                            xl: 37,
                        },
                    },
                }),
            }}
        >
            {!smScreen ? (
                <>
                    <LogosGrid sx={{ height: '120px', lineHeight: '120px' }} logos={logos} />
                    <LogosGrid
                        sx={{ height: '120px', lineHeight: '120px', marginTop: '-48px' }}
                        logos={logos2}
                    />
                </>
            ) : (
                <LogosGrid sx={{ pt: 5, pb: 5 }} logos={[...logos, ...logos2]} />
            )}
        </Box>
    )
}

export default HomepageLogos
